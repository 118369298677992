<template>
    <div>
      <patent-working-reminder-list></patent-working-reminder-list>
    </div>
  </template>
  
  <script>
  
  export default {
  }
  </script>
  
  
  
  